<template>
	<div
		:id="containerId"
		:class="containerClass"
	>
		<div class="form-group" :class="{ 'required' : required }">
			<label v-if="label" :for="name">
				{{ label }}
				<a
					v-if="help"
					:id="id"
					tabindex="0"
					class="btn btn-outline-info btn-circle btn-circle-xsm btn-help"
					data-toggle="popover"
					data-trigger="focus"
					role="button"
					:data-content="help.text"
				>
					<i class="fa fa-question"></i>
				</a>
			</label>
			<input
				ref="input"
				:type="type"
				:name="name"
				:class="[{ 'is-invalid' : error }, className]"
				:pattern="pattern"
				:minlength="minLength"
				:maxlength="maxLength"
				:title="title"
				:disabled="disabled"
				:required="required"
				:readonly="readonly"
				:placeholder="placeholder"
				:value="value"
				v-mask="mask"
				@input="updateValue"
			>
			<div v-if="error" class="invalid-feedback">
				{{ error }}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			id: String,
			placeholder: String,
			type: { type: String, default: 'text' },
			className: { type: String, default: 'form-control' },
			label: String,
			name: String,
			value: String | Number,
			pattern: String,
			minLength: Number,
			maxLength: Number,
			title: String,
			required: Boolean,
			readonly: Boolean,
			disabled: { type: Boolean, default: false },
			containerId: String,
			containerClass: { type: String, default: 'col-12 col-xl-4 col-md-6' },
			help: Object,
			error: String,
			mask: { type: String | Array, default: '' },
			startDate: { type: String, default: '' }
		},
		mounted() {
			$(this.$refs.input)
				.filter('input[type="datetime"]')
				.datetimepicker({
					minDate: true,
					startDate: new Date(),
					format: "dd/mm/yyyy hh:ii:ss",
					language: 'pt-BR',
					todayHighlight: true,
					autoclose: true,
					fontAwesome: true,
				})
				.on('change', this.updateValue);
		},
		watch: {
			startDate(newValue, oldValue) {
				if ($(this.$refs.input).data('datetimepicker')) {
            		$(this.$refs.input).data('datetimepicker').setStartDate(newValue);
       	 		}
			}
		},
		methods: {
			updateValue(e) {
				let value = e.target.value;
				
				if (this.pattern) {
					let re = RegExp(`^${this.pattern}+$`);
					
					if (!re.test(value)) {
						value = value.substring(0, value.length - 1);
					}
				}

				e.target.value = value;
				
				this.$emit('input', e.target.value);
			}
		}
	}
</script>
